<div *ngIf="!loading">
      <form [formGroup]="ticketForm">
        <mat-card>
          <mat-card-title>Ticket Entry</mat-card-title>
          <div class="innerCard">
            <mat-card-content>
              <div>
                <mat-form-field appearance="fill" class="inputField required" aria-required="true">
                  <mat-label>Name:</mat-label>
                  <mat-select formControlName="person" name="person">
                    <mat-option *ngFor="let user of users" [value]="user.fname">{{user.fname}}</mat-option>
                  </mat-select>
                  <div *ngIf="getPerson().invalid && getPerson().touched">Name required</div> 
                </mat-form-field>
              </div>
              <!-- <div>
                <mat-form-field appearance="fill" class="inputField">
                  <mat-label>Working With:</mat-label>
                  <mat-select formControlName="workingWith" name="workingWith">
                    <mat-option *ngFor="let user of filteredUsers" [value]="user.fname">{{user.fname}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

              <div>
                <mat-slide-toggle formControlName="cash" [checked]="cashChecked" (toggleChange)="toggled()">Cash</mat-slide-toggle>
              </div>
              <div>
                <mat-form-field appearance="fill" class="inputField required" aria-required="true">
                  <mat-label>Price</mat-label>
                  <input matInput type="number" formControlName="price" name="price">
                  <div *ngIf="getPrice().invalid && getPrice().touched">Price required</div> 
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="fill" class="inputField required" aria-required="true">
                  <mat-label>Tip (if cash, put 0)</mat-label>
                  <input matInput type="number" formControlName="tip" name="tip">
                  <div *ngIf="getTip().invalid && getTip().touched">Tip required</div> 
                </mat-form-field>
              </div>
              <div *ngIf="!cashChecked">
                <mat-form-field appearance="fill" class="inputField required" aria-required="true">
                  <mat-label>Transaction Number on Receipt</mat-label>
                  <input matInput type="number" formControlName="transNum" name="transNum">
                </mat-form-field>
              </div>
            </mat-card-content>
          </div>
          <mat-card-actions align="center">
            <button type="reset" mat-button color="warn" (click)="onCancel()">Cancel</button>
            <button type="submit" mat-button color="primary" (click)="onSubmit()">Submit</button>
          </mat-card-actions>
        </mat-card>
      </form>
</div>
<mat-spinner *ngIf="loading" class="loading"></mat-spinner>