<h1 mat-dialog-title>Please double-check</h1>
<div mat-dialog-content>
    <h2>
        <p>Technician: </p>
        <p>{{data.person}}</p>
    </h2>
    <h2 *ngIf="data.workingWith">
        <p>Working with: </p>
        <p>{{data.workingWith}}</p>    
    </h2>
    <h2>
        <p>Price: </p>
        <p>{{data.price}}</p>
    </h2>
    <h2>
        <p>Tip: </p>
        <p>{{data.tip}}</p>
    </h2>
    <h2 *ngIf="!data.cash">
        <p>Transaction Number: </p>
        <p>{{data.transNum}}</p>
    </h2>
</div>
<div mat-dialog-actions align="center">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data">Submit</button>
</div>